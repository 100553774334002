import React from 'react';
import PromoteCta from '../components/common/PromoteCta';

import Layout from '../components/layout';
import SEO from '../components/seo';

function ShareFaithsStory() {
  return (
    <Layout bg='bg-faith-purple'>
      <SEO
        keywords={[
          `work-life balance`,
          `parent support`,
          `lone parent`,
          `childcare`,
          'ireland',
        ]}
        title='Share Faiths Story'
      />
      <section className='h-screen   w-full'>
        <PromoteCta />
      </section>{' '}
    </Layout>
  );
}

export default ShareFaithsStory;
